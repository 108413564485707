// Dictionaries that translate between codes and names

export const VERSION = '20221220';

export const Status = {
    successful: 'successful',
    unsuccessful: 'unsuccessful',
    wip: 'wip',
    unscheduled: 'unscheduled',
    scheduled: 'scheduled',
    missing: 'missing',
};

export const FIRST_CATALOG_YEAR = 2020;
export const MOST_RECENT_CATALOG_YEAR = 2025;
export const TRANSITION_YEAR = 2024;

export const SuccessfulButNoCredit = ['WV', 'RD', 'RCD', 'RLP', '#A', '#AB', '#B', '#BC', '#C', '#CD', '#D', '#LP', '#P', '#S', 'NoCredit'];
export const gradeToStatus = (grade) => {
    const successfulGrades = ['A', 'AB', 'B', 'BC', 'C', 'CD', 'D', 'P', 'LP', 'S', 'CR', 'TR',
        'AB*', 'B*', 'BC*', 'C*', 'CD*', 'D*', 'P*', 'LP*', 'WV', 'RD', 'RCD', 'RLP', '#A', '#AB', '#B',
        '#BC', '#C', '#CD', '#D', '#LP', '#P', '#S', 'NoCredit', 'successful'];
    const wipGrades = ['', 'WIP', 'U*', 'F*', 'NP*', 'NR', 'NG', 'PIP', 'wip'];
    const unsuccessfulGrades = ['F', 'NP', 'U', 'W', 'AU', 'AX', 'RF', 'RNP', '#NP', '#F', '#W', 'unsuccessful'];
    return grade.length > 3 && grade !== 'NoCredit' ? grade :
        successfulGrades.includes(grade) ? Status.successful :
            wipGrades.includes(grade) ? Status.wip :
                unsuccessfulGrades.includes(grade) ? Status.unsuccessful : Status.unscheduled;
}

export const SpecialYears = [
    1000, // Explicitly deleted
    1800, // Bachelor's Degree
    1901, // AP Advanced Placement
    1902, // CR College-Level Examination Credit
    1903, // TR transfer
    1904, // P3 PLTW
    1905, // WV Waived
    1906, // IB International Baccalaureate
    1907, // CLEP International Baccalaureate
    1909, // CR Credit
];

export const futureCourse = (status) => (
    status === Status.unscheduled || status === Status.scheduled
);

export const unsuccessfulCourse = (status) => (
    status === Status.unsuccessful || status === Status.missing
);

export const successfulCourse = (status) => (
    status === Status.successful || status === Status.wip
);

export const LowestEnrollmentYear = 2000;

export const isRemoved = (year, term) => (
    year === 1000 && term === 'RM'
);

export const yearToHeading = (year) => (
    year === 1800 ? "Bachelor's Degree" :
    year === 1901 ? 'Advanced Placement' :
    year === 1902 ? 'Credit by MSOE Examination' :
    year === 1903 ? 'Transfer Work' :
    year === 1904 ? 'Project Lead the Way' :
    year === 1905 ? 'Waived Credit' :
    year === 1906 ? 'International Baccalaureate' :
    year === 1907 ? 'CLEP Credit' :
    year === 1909 ? 'Credit by MSOE Evaluation' : `${year-1}-${year%100}`
);

const TermByHeading = {
    'Fall Quarter': 'Q1',
    'Winter Quarter': 'Q2',
    'Spring Quarter': 'Q3',
    'Summer Quarter': 'Q4',
    'Fall Semester': 'S1',
    'Spring Semester': 'S2',
    'Summer Semester': 'S3',
};

export const pdfHeadingToYearAndTerm = (heading) => (
    heading === "Previous Degree Earned : Undergraduate" ? [1800, 'UG'] :
    heading === 'The College Board : Advanced Placement' ? [1901, 'AP'] :
    heading === 'The College Board : CL Clep Credit' ? [1907, 'CL'] :
    heading === 'International Baccalaureate : International Baccalaureate' ? [1906, 'IB'] :
    heading === 'Transfer Work : TR' ? [1903, 'TR'] :
    heading === 'Waive Credit : WV' ? [1905, 'WV'] :
    heading === 'PLTW TODO : P3' ? [1904, 'P3'] :
    heading === 'Credit by MSOE Examination : CR' ? [1902, 'CR'] :
    heading === 'Credit by MSOE Evaluation : CR' ? [1909, 'CR'] :
        [parseInt(heading.slice(heading.indexOf('-') + 1, heading.indexOf(' : ')).trim()),
            TermByHeading[heading.slice(heading.indexOf(' : ') + 3).trim()]]
)

export const TermByCode = {
    'Q1': 'Fall Quarter',
    'Q2': 'Winter Quarter',
    'Q3': 'Spring Quarter',
    'Q4': 'Summer Quarter',
    'S1': 'Fall Semester',
    'S2': 'Spring Semester',
    'S3': 'Summer Semester',
    'T1': 'Fall Semester',
    'T2': 'Spring Semester',
    'T3': 'Summer Semester',
};

export const isQuarterTerm = (term) => (
    term === 'Q1' || term === 'Q2' || term === 'Q3' || term === 'Q4'
)

export const isSemesterTerm = (term) => (
    term === 'S1' || term === 'S2' || term === 'S3' || term === 'T1' || term === 'T2' || term === 'T3'
)

export const maxTermsPerYear = (year) => (
    year < TRANSITION_YEAR ? 4 : 3
)

export const yearToSummerTermCode = (year) => (
    year < TRANSITION_YEAR ? 'Q4' : 'S3'
)

export const yearHeadingToTermCode = (heading, year) => (
    year === 1800 ? 'UG' :
    year === 1901 ? 'AP' :
    year === 1902 ? 'CR' :
    year === 1903 ? 'TR' :
    year === 1904 ? 'P3' :
    year === 1905 ? 'WV' :
    year === 1906 ? 'IB' :
    year === 1907 ? 'CL' :
    year === 1909 ? 'CR' :
    year < TRANSITION_YEAR ? (heading === 'Fall' ? 'Q1' :
                   heading === 'Winter' ? 'Q2' :
                   heading === 'Spring' ? 'Q3' : 'Q4') :
    heading === 'Fall' ? 'S1' :
    heading === 'Spring' ? 'S2' : 'S3'
)

export const MajorsByCode = {
    'AE': 'Architectural Engineering',
    'BEPS': 'Architectural Engineering (Electrical)',
    'BMS': 'Architectural Engineering (Mechanical)',
    'BSS': 'Architectural Engineering (Structural)',
    'AS': 'Actuarial Science',
    'BBA': 'Business Administration',
    'ACCT': 'BBA in Accounting',
    'FIN': 'BBA in Finance',
    'MRKT': 'BBA in Marketing',
    'MGMT': 'Management',
    'OPSYS': 'BBA in Operations and Systems Management',
    'BE': 'Biomedical Engineering',
    'BIOE': 'Chemical and Biomolecular Engineering',
    'BSCVE': 'Civil Engineering',
    'CE': 'Computer Engineering',
    'CSCE': 'Computer Engineering and Computer Science',
    'CM': 'Construction Management',
    'CS': 'Computer Science',
    'BEML': 'MS: Biomedical Engineering + Machine Learning',
    'CEML': 'MS: Computer Science + Machine Learning',
    'CSML': 'MS: Computer Science + Machine Learning',
    'EEML': 'MS: Electrical Engineering + Machine Learning',
    'SEML': 'MS: Software Engineering + Machine Learning',
    'CONM': 'Civil Engineering (Construction Management)',
    'EWRE': 'Civil Engineering (EWRE)',
    'MUNI': 'Civil Engineering (Municipal)',
    'STRE': 'Civil Engineering (Structural)',
    'TRNSP': 'Civil Engineering (Transportation)',
    'EE': 'Electrical Engineering',
//    'EEX': 'Electrical Engineering AAS-EET to BSEE',
    'IE': 'Industrial Engineering',
    'ME': 'Mechanical Engineering',
    'NU': 'Nursing',
    'SE': 'Software Engineering',
    'UX': 'User Experience',
    'UXCD': 'User Experience',
};

/*
 Architectural Engineering, Civil Engineering, and Business offer concentrations/specializations.
 In STAT we create a separate track for each of these concentrations. The following are used to
 translate back and forth between the concentration and "major" abbreviations.
 */
export const ConcentrationByName = {
    'Bldg Electrical Systems': 'BEPS',
    'Bldg Mechanical Systems': 'BMS',
    'Bldg Structural Systems': 'BSS',
    'Accounting': 'ACC',
    'Finance': 'FIN',
    'Marketing': 'MKT',
    'Supply Chain/Logistics': 'ISCLD',
    'Construction Management': 'CONM',
    'Env & Water Resources Eng': 'EWRE',
    'Municipal': 'MUNI',
    'Structural Engineering': 'STRE',
    'Transportation': 'TRNSP',
};

export const ConcentrationByCode = {
    'BEPS': 'Bldg Electrical Systems',
    'BMS': 'Bldg Mechanical Systems',
    'BSS': 'Bldg Structural Systems',
    'ACC': 'Accounting',
    'FIN': 'Finance',
    'MKT': 'Marketing',
    'ISCLD': 'Supply Chain/Logistics',
    'CONM': 'Construction Management',
    'EWRE': 'Env & Water Resources Eng',
    'MUNI': 'Municipal',
    'STRE': 'Structural Engineering',
    'TRNSP': 'Transportation',
};

export const MajorAndConcentrationByAbbreviation = {
    'AE': ['AE', ''],
    'BEPS': ['AE', 'BEPS'],
    'BMS': ['AE', 'BMS'],
    'BSS': ['AE', 'BSS'],
    'AS': ['AS', ''],
    'BBA': ['BBA', ''],
    'ACCT': ['BBA', 'ACC'],
    'FIN': ['BBA', 'FIN'],
    'MRKT': ['BBA', 'MKT'],
    'MGMT': ['BBA', ''],
    'OPSYS': ['BBA', 'ISCLD'],
    'BE': ['BE', ''],
    'BIOE': ['BIOE', ''],
    'CE': ['CE', ''],
    'CSCE': ['CSCE', ''],
    'CM': ['CM', ''],
    'CS': ['CS', ''],
    'BEML': ['BEML', ''],
    'CEML': ['CEML', ''],
    'CSML': ['CSML', ''],
    'EEML': ['EEML', ''],
    'SEML': ['SEML', ''],
    'BSCVE': ['BSCVE', ''],
    'CONM': ['BSCVE', 'CONM'],
    'EWRE': ['BSCVE', 'EWRE'],
    'MUNI': ['BSCVE', 'MUNI'],
    'STRE': ['BSCVE', 'STRE'],
    'TRNSP': ['BSCVE', 'TRNSP'],
    'EE': ['EE', ''],
//    'EEX': ['EEX', ''],
    'IE': ['IE', ''],
    'ME': ['ME', ''],
    'NU': ['NU', ''],
    'SE': ['SE', ''],
    'UX': ['UX', ''],
};

export const getValidMajorCode = (code, concentration) => (
    concentration !== '' ? (
        concentration === 'ACC' ? 'ACCT' :
        concentration === 'ISCLD' || concentration === 'MIS' || concentration === 'ITS' ? 'OPSYS' :
        concentration === 'MKT' ? 'MRKT' :
        concentration === 'BE_GN' ? 'BE' : concentration
        ) :
        code === 'AE' || code === 'AS' || code === 'BE' || code === 'BEML' || code === 'BIOE' || code === 'BSCVE'
        || code === 'CE' || code === 'CEML' || code === 'CM' || code === 'CS' || code === 'CSML' || code === 'EE'
        || code === 'EEML' || code === 'IE' || code === 'MGMT' || code === 'ME' || code === 'NU' || code === 'SE'
        || code === 'SEML' || code === 'UX' || code === 'CSCE' || code === 'ACCT' || code === 'FIN' || code === 'MGMT'
        || code === 'MRKT' || code === 'OPSYS' ? code :
        code === 'BBA' ? 'MGMT' :
        code === 'EEX' ? 'EE' :
        code === 'CBE' ? 'BIOE' :
        code === 'AECM' ? 'AE' :
        code === 'BBACS' ? 'CS' :
        code === 'BE_GN' ? 'BE' :
        code === 'BEBBA' ? 'BE' :
        code === 'BECS' ? 'BE' :
        code === 'BEEE' ? 'BE' :
        code === 'CECS' ? 'CE' :
        code === 'EEIE' ? 'EE' :
        code === 'IEBBA' ? 'IE' :
        code === 'AEAEM' ? 'AE' :
        code === 'BSE' ? 'ME' :
        code === 'CVE' ? 'BSCVE' :
        code === 'MIS' ? 'OPSYS' :
        code === 'NUACC' ? 'NU' :
        code === 'UXCD' ? 'UX' : 'ND'
)

export const MajorsByName = {
    'Architectural Engineering': 'AE',
    'Architectural Engineering (Electrical)': 'BEPS',
    'Architectural Engineering (Mechanical)': 'BMS',
    'Architectural Engineering (Structural)': 'BSS',
    'Actuarial Science': 'AS',
    'Business Administration': 'BBA',
    'BBA in Accounting': 'ACCT',
    'BBA in Finance': 'FIN',
    'BBA in Marketing': 'MRKT',
    'BBA in Management': 'MGMT',
    'BBA in Operations and Systems Management': 'OPSYS',
    'Biomedical Engineering': 'BE',
    'Chemical and Biomolecular Engineering': 'BIOE',
    'Civil Engineering': 'BSCVE',
    'Civil Engineering (Construction Management)': 'CONM',
    'Civil Engineering (EWRE)': 'EWRE',
    'Civil Engineering (Municipal)': 'MUNI',
    'Civil Engineering (Structural)': 'STRE',
    'Civil Engineering (Transportation)': 'TRNSP',
    'Computer Engineering': 'CE',
    'Computer Engineering and Computer Science': 'CSCE',
    'Construction Management': 'CM',
    'Computer Science': 'CS',
    'MS: Biomedical Engineering + ML': 'BEML',
    'MS: Computer Engineering + ML': 'CEML',
    'MS: Computer Science + ML': 'CSML',
    'MS: Electrical Engineering + ML': 'EEML',
    'MS: Software Engineering + ML': 'SEML',
    'Electrical Engineering': 'EE',
//    'Electrical Engineering AAS-EET to BSEE': 'EEX',
    'Industrial Engineering': 'IE',
    'Mechanical Engineering': 'ME',
    'Nursing': 'NU',
    'Software Engineering': 'SE',
    'User Experience': 'UX',
    'Architectural Engineering/Construction Management': 'AECM',
    'Architectural Engineering/MS--Architectural Engineering': 'AEAEM',
    'Business Administration/Computer Science': 'BBACS',
    'Biomedical Engineering/Business Administration': 'BEBBA',
    'Biomedical Engineering/Computer Science': 'BECS',
    'Biomedical Engineering/Electrical Engineering': 'BEEE',
    'Civil Engineering/MS--Civil Engineering': 'CVE',
//    'Computer Engineering/Computer Science': 'CECS',
    'Electrical Engineering/Industrial Engineering': 'EEIE',
    'Business Administration/Industrial Engineering': 'IEBBA',
};

/*
The quarter based minors begin with "Q-".
 */
export const MinorByCode = {
    'M_BAI': ' Business Analysis and Improvement Minor',
    'M_ENTR': ' Business Entrepreneurship Minor', // M_ENT DUP
    'M_ENT': ' Business Entrepreneurship Minor',  // M_ENT DUP
    'M_CHM': ' Chemistry Minor',                 // M_CHM  DUP
    'M_CE': ' Computer Engineering Minor',
    'M_CYB': ' Cybersecurity Minor',
    'M_DS': ' Data Science Minor',
    'M_EE': ' Electrical Engineering Minor',      // unconfirmed
    'M_FI_S': ' Finance Minor',      // unconfirmed
    'M_GER_S': ' Gerontology Minor',      // unconfirmed
    'M_MGT': ' Management Minor',
    'M_MKT2': ' Marketing Minor',      // unconfirmed
    'MATH': ' Mathematics Minor',      // unconfirmed
    'M_PHY': ' Physics Minor',
    'M_PSY': ' Psychology Minor',
    'M_SCM': ' Supply Chain Management Minor',      // unconfirmed
    'M_TC': ' Technical Communication Minor',
    'M_TS_S': ' Technical Sales Minor',      // unconfirmed
    'M_UX_S': ' User Experience Minor',      // unconfirmed
    'C_AI': 'Q-AI for Emerging Applications Certificate',      // unconfirmed
    'C_GER': 'Q-Gerontology Certificate',      // unconfirmed
    'M_BA': 'Q-Business Administration Minor',
    'CHEM': 'Q-Chemistry Minor',      // unconfirmed
    'M_FI': 'Q-Finance Minor',
    'GS': 'Q-German Minor',      // unconfirmed
    'M_GER': 'Q-Gerontology Minor',
    'M_ISC': 'Q-International Supply Chain and Logistics Minor',
    'M_ITS': 'Q-Management Information Systems Minor',
    'M_MKT': 'Q-Marketing Minor',
    'M_MTH': 'Q-Mathematics Minor',      // unconfirmed
    'PHYS': 'Q-Physics Minor',      // unconfirmed
    'PSY': 'Q-Psychology Minor',      // unconfirmed
    'M_SP': 'Q-Spanish Minor',
    'TC': 'Q-Technical Communication Minor',      // unconfirmed
    'M_TS': 'Q-Technical Sales Minor',
    'M_UX': 'Q-User Experience Minor',
};

export const MinorTransitionURL = {
    'M_BA': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949846190575',
    'CHEM': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/943771694766',
    'M_FI': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949846190575',
    'GS': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949854691499',
    'M_GER': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/961942219898',
    'M_ISC': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949846190575',
    'M_ITS': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949846190575',
    'M_MKT': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949846190575',
    'M_MTH': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949857906219',
    'PHYS': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949856776442',
    'PSY': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/943770733909',
    'M_SP': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949859694827',
    'TC': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/943767038477',
    'M_TS': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/949846190575',
    'M_UX': 'https://msoe.app.box.com/s/c3ikqo42pwn0xrzuvyjxl4r38uw82ma9/file/943772712329',
    'M_BAI': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1851',
    'M_ENT': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1852',
    'M_CHM': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1843',
    'M_CE': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1854',
    'M_DS': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1853',
    'M_EE': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1857',
    'M_FI_S': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1831',
    'M_GER_S': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1848',
    'M_MGT': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1835',
    'M_MKT2': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1832',
    'MATH': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1849',
    'M_PHY': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1844',
    'M_PSY': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1830',
    'M_SCM': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1834',
    'M_TC': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1828',
    'M_TS_S': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1833',
    'M_UX_S': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1829',
    'C_AI': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1856',
    'C_GER': 'https://catalog.msoe.edu/preview_program.php?catoid=35&poid=1847',
};

export const MinorByName = {
    'Q-Business Administration Minor': 'M_BA',
    'Q-Chemistry Minor': 'CHEM',
    'Q-Finance Minor': 'M_FI',
    'Q-German Minor': 'GS',
    'Q-Gerontology Minor': 'M_GER',
    'Q-International Supply Chain and Logistics Minor': 'M_ISC',
    'Q-Management Information Systems Minor': 'M_ITS',
    'Q-Marketing Minor': 'M_MKT',
    'Q-Mathematics Minor': 'M_MTH',
    'Q-Physics Minor': 'PHYS',
    'Q-Psychology Minor': 'PSY',
    'Q-Spanish Minor': 'M_SP',
    'Q-Technical Communication Minor': 'TC',
    'Q-Technical Sales Minor': 'M_TS',
    'Q-User Experience Minor': 'M_UX',
    ' Business Analysis and Improvement Minor': 'M_BAI',
    ' Business Entrepreneurship Minor': 'M_ENT',
    ' Chemistry Minor': 'M_CHM',
    ' Computer Engineering Minor': 'M_CE',
    ' Cybersecurity Minor': 'M_CYB',
    ' Data Science Minor': 'M_DS',
    ' Electrical Engineering Minor': 'M_EE',
    ' Finance Minor': 'M_FI_S',
    ' Gerontology Minor': 'M_GER_S',
    ' Management Minor': 'M_MGT',
    ' Marketing Minor': 'M_MKT2',
    ' Mathematics Minor': 'MATH',
    ' Physics Minor': 'M_PHY',
    ' Psychology Minor': 'M_PSY',
    ' Supply Chain Management Minor': 'M_SCM',
    ' Technical Communication Minor': 'M_TC',
    ' Technical Sales Minor': 'M_TS_S',
    ' User Experience Minor': 'M_UX_S',
    'AI for Emerging Applications Certificate': 'C_AI',
    'Gerontology Certificate': 'C_GER',
};

export const ProgramDirector = {
    'AE': 'Jayme Radomski',
    'BEPS': 'Jayme Radomski',
    'BMS': 'Jayme Radomski',
    'BSS': 'Jayme Radomski',
    'AS': 'Yvonne Yaz',
    'BBA': 'Ramiro Serrano-Garcia',
    'ACCT': 'Ramiro Serrano-Garcia',
    'FIN': 'Ramiro Serrano-Garcia',
    'MRKT': 'Ramiro Serrano-Garcia',
    'MGMT': 'Ramiro Serrano-Garcia',
    'OPSYS': 'Ramiro Serrano-Garcia',
    'BE': 'Jeff LaMack',
    'BIOE': 'Faisal Shaikh',
    'CE': 'Russ Meier',
    'CSCE': 'Russ Meier and Derek Riley',
    'CM': 'Mark Rounds',
    'CS': 'Derek Riley',
    'BEML': 'Jeff LaMack and Eric Durant',
    'CEML': 'Russ Meier and Eric Durant',
    'CSML': 'Derek Riley and Eric Durant',
    'EEML': 'Steve Holland and Eric Durant',
    'SEML': 'Chris Taylor and Eric Durant',
    'BSCVE': 'Willie Gonwa',
    'CONM': 'Willie Gonwa',
    'EWRE': 'Willie Gonwa',
    'MUNI': 'Willie Gonwa',
    'STRE': 'Willie Gonwa',
    'TRNSP': 'Willie Gonwa',
    'EE': 'Steve Holland',
    'IE': 'Leah Newman',
    'ME': 'Nebojsa Sebastijanovic',
    'NU': 'Cheryl Peterson',
    'SE': 'Chris Taylor',
    'UX': 'Nadya Shalamova',
    'UXCD': 'Nadya Shalamova',
};

export const DragDropTypes = {
    QuarterCourse: 'QUARTER-COURSE',
    QuarterUnassignedElective: 'QUARTER-UE',
    QuarterAssignedElective: 'QUARTER-AE',
    SemesterCourse: 'SEMESTER-COURSE',
    SemesterUnassignedElective: 'SEMESTER-UE',
    SemesterAssignedElective: 'SEMESTER-AE',
};
export const AllDragDrop = Object.values(DragDropTypes);
export const QuarterDragDrop = [ DragDropTypes.QuarterCourse, DragDropTypes.QuarterUnassignedElective, DragDropTypes.QuarterAssignedElective, DragDropTypes.SemesterUnassignedElective ];
export const SemesterDragDrop = [ DragDropTypes.SemesterCourse, DragDropTypes.SemesterUnassignedElective, DragDropTypes.SemesterAssignedElective, DragDropTypes.QuarterUnassignedElective ];
